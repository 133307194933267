import { h, FunctionalComponent } from "preact";
import Match, { Link } from "preact-router/match";
import { useTranslation } from "react-i18next";
import { Home, Profile, Ranking, Stats } from "./assets";

const NavLink: FunctionalComponent<{ href: string }> = ({ children, href }) => (
    <Match>
        {({ path }: { path: string }) => {
            const isRoot = href === "/";
            const matches = !isRoot && path.startsWith(href);

            return (
                <Link
                    class="flex items-center justify-center w-full h-full border-t-4 border-transparent text-brand-purple-3"
                    href={matches ? path : href}
                    activeClassName="border-brand-purple-2"
                >
                    {children}
                </Link>
            );
        }}
    </Match>
);

const Navigation: FunctionalComponent = () => {
    const { i18n } = useTranslation();

    return (
        <nav
            data-sal="slide-up"
            className="mx-auto max-w-screen-md flex-shrink-0 h-16 px-6 bg-brand-purple-1 rounded-t-3xl relative z-30"
        >
            <ul className="flex w-full h-full lg:max-w-3xl lg:mx-auto">
                <li className="w-full h-full">
                    <NavLink href="/dashboard">
                        <Home />
                    </NavLink>
                </li>
                <li className="w-full h-full">
                    <NavLink href="/stats">
                        <Stats />
                    </NavLink>
                </li>
                <li className="w-full h-full">
                    <NavLink href="/profile">
                        <Profile />
                    </NavLink>
                </li>
                {i18n.language == "en" ? null : (
                    <li className="w-full h-full">
                        <NavLink href="/rankings">
                            <Ranking />
                        </NavLink>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Navigation;
