import { get } from "./config";

export function getClusters(): Promise<{ clusters: Cluster[]; quest: Quest }> {
    return get("dashboard");
}

export function getStats(): Promise<Stats> {
    return get("stats");
}

export function getUser(): Promise<User> {
    return get(`player`);
}

export function getPlayerOptions(): Promise<PlayerOptions> {
    return get("player/options");
}

export function getAvatars(): Promise<Avatar[]> {
    return get("avatars");
}

export function getRankings(): Promise<Rankings> {
    return get("ranking");
}

export function getQuiz(id: string): () => Promise<Quiz> {
    return () => get(`quizzes/${id}`);
}

export function getQuizzes(
    skip = 0,
    take = 10,
    includeProgress = false
): () => Promise<Quiz[]> {
    return () =>
        get(
            `quizzes/?skip=${skip}&take=${take}&includeProgress=${
                includeProgress ? "1" : "0"
            }`
        );
}

export function getGame(id: string): () => Promise<Game> {
    return () => get(`games/${id}`);
}

export function getPlans(): Promise<Plan[]> {
    return get("billing/plans");
}

export function getBillingPortalUrl(): Promise<BillingSession> {
    return get("billing/portal/session");
}

export function getBillingCheckoutUrl(
    priceId: string
): () => Promise<BillingSession> {
    return () => get(`billing/checkout/session?priceId=${priceId}`);
}
