import { patch, post } from "./config";

export interface QuizResult {
    result: {
        success: boolean;
        completed: boolean;
    };
    progress: {
        correctness: number;
        success: boolean;
        games?: {
            done: number;
            total: number;
        };
        points?: {
            added: number;
            total: number;
        };
    };
    games?: {
        current: {
            id: string;
            answers: GameOption[];
        };
        next?: {
            id: string;
        };
    };
    reason?: {
        html: string;
    };
}

export function createQuiz(levelId: string): Promise<Quiz> {
    return post("quizzes?includeProgress", {
        body: JSON.stringify({ level_id: levelId }),
    });
}

export function answer(data: {
    optionIds: string[];
    gameId: string;
    quizId: string;
}): Promise<QuizResult> {
    return post(`quizzes/${data.quizId}/answer`, {
        body: JSON.stringify({
            option_ids: data.optionIds,
            game_id: data.gameId,
        }),
    });
}

export function updateProfile(data: {
    name: string;
    avatar?: Avatar | string;
}): Promise<{ profile: UserProfile }> {
    return patch("player/profile", {
        body: JSON.stringify(data),
    });
}

export function signInWithEmail(email: string): Promise<{ success: boolean }> {
    return post("auth/email", {
        body: JSON.stringify({ destination: email }),
    });
}

export function updateAvatar(
    avatar: Avatar | string
): Promise<{ profile: UserProfile }> {
    return patch("player/profile", {
        body: JSON.stringify({ avatar }),
    });
}
