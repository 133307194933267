import { h, FunctionalComponent, Fragment } from "preact";
import AcceptLanguageParser from "accept-language-parser";
import { useState } from "preact/hooks";
import { useUser } from "../hooks/user";
import i18n from "../i18n";
import useLoader from "../hooks/loader";

export const getLocaleFromCode = (code?: string) => {
    return (
        AcceptLanguageParser.pick(["pt-BR", "pt-PT", "en"], code || "en") ||
        "en"
    );
};

const I18nProvider: FunctionalComponent = ({ children }) => {
    const [ready, setReady] = useState(false);
    const { toggleLoader } = useLoader();

    toggleLoader(!ready);

    useUser({
        onSuccess: (user) => {
            i18n.changeLanguage(
                user?.profile?.locale || getLocaleFromCode(navigator.language)
            )
                .then(() => setReady(true))
                .catch(() => setReady(true));
        },
    });

    return <Fragment>{children}</Fragment>;
};

export default I18nProvider;
