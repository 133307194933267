import { h, FunctionalComponent } from "preact";
import { Spinner as SpinnerAsset } from "./assets";
import avatar from "../assets/logo.svg";
import sal from "sal.js";
import { useEffect } from "preact/hooks";
import cc from "classcat";

interface SpinnerProps {
    className?: string;
}

export const Spinner: FunctionalComponent<SpinnerProps> = ({
    className = "w-16 h-16 text-brand-purple-1",
}) => <SpinnerAsset className={className} />;

const Loader: FunctionalComponent = () => {
    useEffect(() => {
        sal();
    }, []);

    return (
        <div
            className={cc([
                "absolute inset-0 z-20 flex items-center justify-center transition-opacity bg-white",
            ])}
        >
            <img
                src={avatar}
                data-sal="fade"
                data-sal-delay="500"
                className="w-28 h-auto animate-bounce"
            />
        </div>
    );
};

export default Loader;
