import { FunctionalComponent, h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo.svg";
import cc from "classcat";

interface InstallPopUpProps {
    event: BeforeInstallPromptEvent;
    onCancel: () => void;
    onFinish: (userChoice: BeforeInstallPromptUserChoice) => void;
}

const InstallPopUp: FunctionalComponent<InstallPopUpProps> = (props) => {
    const { event, onCancel, onFinish } = props;
    const { t } = useTranslation();

    const onInstall = useCallback(async () => {
        onCancel();
        // Show the install prompt
        if ("prompt" in event) {
            void event.prompt();
            const userChoice = await event.userChoice;
            console.log(`User response to the install prompt`, userChoice);
            onFinish(userChoice);
        }
    }, [event, onCancel, onFinish]);

    const [show, setShow] = useState(false);

    useEffect(function () {
        const timer = setTimeout(() => setShow(true));
        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            className={cc([
                "fixed bottom-6 left-6 right-6 z-40 transition-all duration-500 delay-1000",
                { "opacity-0 translate-y-10": !show },
            ])}
        >
            <div className="flex space-x-6 bg-gray-900 text-white rounded w-full max-w-sm m-auto p-6">
                <div className="">
                    <img className="w-12 h-12" src={logo} alt="In your face" />
                </div>
                <div className="text-center flex-grow">
                    <p>{t("install.title")}</p>
                    <div className="flex space-x-6 items-center justify-center mt-4">
                        <button
                            onClick={onCancel}
                            className="text-gray-300 text-sm"
                        >
                            {t("install.notnow")}
                        </button>
                        <button
                            className="py-1 px-6 bg-white rounded-xs text-gray-900 rounded-3xl"
                            onClick={onInstall}
                        >
                            {t("install.action")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstallPopUp;
