import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ptPT from "./locales/pt-PT.json";
import ptBR from "./locales/pt-BR.json";
import en from "./locales/en.json";

interface Translation {
    [k: string]: string | Translation;
}

interface Locales {
    [k: string]: Record<string, Translation>;
}

const locales: Locales = {
    "pt-PT": ptPT as Record<string, Translation>,
    "pt-BR": ptBR as Record<string, Translation>,
    en: en as Record<string, Translation>,
};

void i18n.use(initReactI18next).init({
    resources: Object.keys(locales).reduce(
        (resources, locale) => ({
            ...resources,
            [locale]: { translation: locales[locale] },
        }),
        {}
    ),
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
});

export default i18n;
