import { h, FunctionalComponent } from "preact";
import { useEffect } from "preact/hooks";
import { useTranslation } from "react-i18next";
import sal from "sal.js";
import avatar from "../assets/logo-fear.svg";

export const Offline: FunctionalComponent = () => {
    const { t } = useTranslation();

    useEffect(() => {
        sal();
    }, []);

    return (
        <div
            data-sal="fade"
            className="fixed inset-0 z-40 bg-gradient-to-b from-brand-purple-1 to-brand-blue-2 flex flex-col items-center justify-center space-y-1 text-center px-4"
        >
            <img
                src={avatar}
                data-sal="fade"
                className="w-28 h-auto animate-bounce mb-6"
            />
            <p className="text-2xl md:text-3xl text-brand-purple-2 font-display">
                {t("offline.title")}
            </p>
            <p className="text-md md:text-lg text-brand-purple-3">
                {t("offline.description")}
            </p>
        </div>
    );
};

export default Offline;
