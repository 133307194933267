import { FunctionalComponent, Fragment, h } from "preact";
import { CustomHistory } from "preact-router";
import { ReactQueryCacheProvider, QueryCache } from "react-query";
import { createMemoryHistory } from "history";
import { I18nProvider } from "../providers";
import avatar from "../assets/logo.svg";
import Routes from "./routes";
import { useEffect } from "preact/hooks";
import Navigation from "./navigation";
import Match from "preact-router/match";
import { useState } from "preact/hooks";
import Offline from "./offline";
import useLoader from "../hooks/loader";
import Loader from "./loader";
import { Helmet } from "react-helmet";
import InstallPopUp from "./installpopup";

const shouldUseBrowserHistory = ["true", true].includes(
    process.env.PREACT_APP_USE_BROWSER_HISTORY || false
);
const cache = new QueryCache();
const appHistory = shouldUseBrowserHistory
    ? undefined
    : (createMemoryHistory() as unknown as CustomHistory);

const App: FunctionalComponent = () => {
    const [isOnline, setIsOnline] = useState(true);
    const { visible } = useLoader();

    const [showInstall, setShowInstall] = useState<
        BeforeInstallPromptEvent | undefined
    >();

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);

        window.addEventListener("offline", () => setIsOnline(false));
        window.addEventListener("online", () => setIsOnline(true));

        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            setShowInstall(event as BeforeInstallPromptEvent);
        });

        window.addEventListener("appinstalled", () => {
            setShowInstall(undefined);
        });
    }, []);

    return (
        <div
            id="app"
            className="overflow-y-hidden h-screen h-[-webkit-fill-available]"
        >
            <Helmet>
                <title>In Your Face</title>
                <link rel="preload" as="image" href={avatar} />
                <style>{`
                    @media screen and (max-width: 767px) and (orientation: landscape) {
                        html {
                            transform: rotate(-90deg);
                            transform-origin: left top;
                            width: 100vh;
                            height: 100vw;
                            overflow-x: hidden;
                            position: absolute;
                            top: 100%;
                            left: 0;
                        }
                    }
                `}</style>
            </Helmet>
            <ReactQueryCacheProvider queryCache={cache}>
                <I18nProvider>
                    <Routes history={appHistory} />
                    <Match path="/">
                        {({ path }: { path: string }) => {
                            const showNav = [
                                "/dashboard",
                                "/profile",
                                "/stats",
                                "/stats/rankings",
                                "/rankings",
                                "/rankings/total",
                            ].includes(path);

                            return (
                                showNav && (
                                    <div className="absolute bottom-[-1px] w-full">
                                        <Navigation />
                                    </div>
                                )
                            );
                        }}
                    </Match>
                    {visible ? <Loader /> : <Fragment />}
                    {!isOnline ? <Offline /> : <Fragment />}
                    {showInstall ? (
                        <InstallPopUp
                            event={showInstall}
                            onCancel={() => setShowInstall(undefined)}
                            onFinish={() => setShowInstall(undefined)}
                        />
                    ) : null}
                </I18nProvider>
            </ReactQueryCacheProvider>
        </div>
    );
};

export default App;
