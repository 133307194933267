import {
    MutationResultPair,
    QueryConfig,
    QueryResult,
    useMutation,
    useQuery,
    useQueryCache,
} from "react-query";
import { updateProfile } from "../api/mutations";
import {
    getAvatars,
    getUser,
    getBillingPortalUrl,
    getPlayerOptions,
    getPlans,
    getBillingCheckoutUrl,
} from "../api/queries";

export function useUser(
    config?: QueryConfig<User, unknown>
): QueryResult<User> {
    return useQuery("user", getUser, config);
}

export function useAvatars(): QueryResult<Avatar[]> {
    return useQuery("avatars", getAvatars);
}

export function usePlayerOptions(): QueryResult<PlayerOptions> {
    return useQuery("playerOptions", getPlayerOptions);
}

export function usePlans(): QueryResult<Plan[]> {
    return useQuery("plans", getPlans);
}

export function useUpdateUserProfile(): MutationResultPair<
    Pick<User, "profile">,
    unknown,
    { name: string; avatar?: Avatar | string },
    unknown
> {
    const queryCache = useQueryCache();

    return useMutation<
        Pick<User, "profile">,
        unknown,
        { name: string; avatar?: Avatar | string },
        unknown
    >(updateProfile, {
        onSuccess: ({ profile }) => {
            queryCache.setQueryData<User | undefined>("user", (user) => {
                if (!user) {
                    return;
                }
                return { ...user, profile };
            });
        },
    });
}

export function useBillingPortalSession(
    config?: QueryConfig<BillingSession, unknown>
): QueryResult<BillingSession> {
    return useQuery<BillingSession>(
        "billing/portal",
        getBillingPortalUrl,
        config
    );
}

export function useBillingCheckoutSession(
    priceId: string,
    config?: QueryConfig<BillingSession, unknown>
): QueryResult<BillingSession> {
    return useQuery<BillingSession>(
        ["billing/checkout", priceId],
        getBillingCheckoutUrl(priceId),
        config
    );
}
