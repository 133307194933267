import { Fragment, FunctionalComponent, h } from "preact";
import { CustomHistory, route, Route, Router } from "preact-router";
import { useTranslation } from "react-i18next";

import Home from "../routes/home";
import Login from "../routes/login";
import Stats from "../routes/stats";
import NotFoundPage from "../routes/notfound";
import Rankings from "../routes/rankings";
import Profile from "../routes/profile";
import Quiz from "../routes/quiz";

import { useEffect } from "preact/hooks";
import { StyleTransition, TransitionGroup } from "preact-transitioning";

const RedirectDash: FunctionalComponent = () => {
    useEffect(() => {
        route("/dashboard");
    }, []);

    return <Fragment />;
};

const Routes: FunctionalComponent<{
    history?: CustomHistory;
}> = ({ history }) => {
    const { t } = useTranslation();

    useEffect(() => {
        const leave = function (e: PopStateEvent) {
            const result = confirm(t("common.leave"));

            if (result) {
                window.history.go(-1);
            } else {
                e.stopImmediatePropagation();
                e.preventDefault();
            }
        };

        window.addEventListener("popstate", leave);

        return () => {
            window.removeEventListener("popstate", leave);
        };
    });

    return (
        <TransitionGroup>
            <StyleTransition
                in
                appear
                duration={2000}
                styles={{
                    enter: { opacity: 0 },
                    enterActive: { opacity: 1 },
                    appear: { opacity: 0 },
                    appearActive: { opacity: 1 },
                    exit: { opacity: 1 },
                    exitActive: { opacity: 0 },
                }}
            >
                <Router history={history}>
                    <Route path="/" component={RedirectDash} />
                    <Route path="/dashboard" component={Home} />
                    <Route path="/quiz" component={Quiz} />
                    <Route path="/login" component={Login} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/stats/:rest*" component={Stats} />
                    <Route path="/rankings/:rest*" component={Rankings} />
                    <NotFoundPage default />
                </Router>
            </StyleTransition>
        </TransitionGroup>
    );
};

export default Routes;
