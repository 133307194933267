import { useState } from "preact/hooks";

const useLoader = () => {
    const [visible, setVisibility] = useState(false);

    const showLoader = () => {
        setVisibility(true);
    };

    const hideLoader = () => {
        if (visible) {
            setTimeout(() => setVisibility(false), 1000);
        }
    };

    const toggleLoader = (toggle: boolean) => {
        toggle ? showLoader() : hideLoader();
    };

    return {
        visible,
        toggleLoader,
        showLoader,
        hideLoader,
    };
};

export default useLoader;
