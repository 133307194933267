import { route } from "preact-router";

const REMOTE_API_URL = "https://api.inyourfaceapp.com/v1";
export const API_URL = process.env.PREACT_APP_API_URL || REMOTE_API_URL;
export const ID_URL = process.env.PREACT_APP_ID_URL || REMOTE_API_URL;

const UNAUTHENTICATED_REQUEST_STATUS = [401, 403];

async function request<T>(
    endpoint: string,
    customOptions: RequestInit = {}
): Promise<T> {
    const url = `${API_URL}/${endpoint}`;
    const options: RequestInit = {
        ...customOptions,
        headers: {
            "Content-Type": "application/json",
            ...customOptions.headers,
        },
        credentials: "include",
    };

    const response = await fetch(url, options);

    if (UNAUTHENTICATED_REQUEST_STATUS.includes(response.status)) {
        route("/login");
    }

    return response.json() as Promise<T>;
}

type HttpRequest = typeof request;

export const get: HttpRequest = (endpoint, options = {}) =>
    request(endpoint, { ...options, method: "GET" });

export const post: HttpRequest = (endpoint, options = {}) =>
    request(endpoint, { ...options, method: "POST" });

export const put: HttpRequest = (endpoint, options = {}) =>
    request(endpoint, { ...options, method: "PUT" });

export const patch: HttpRequest = (endpoint, options = {}) =>
    request(endpoint, { ...options, method: "PATCH" });
