import { h, FunctionalComponent } from "preact";

interface Props {
    className?: string;
}

export const Home: FunctionalComponent<Props> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            d="M29.071 12.938L16.626 1.782a1.226 1.226 0 00-1.6 0L2.581 12.938.318 14.966A.942.942 0 00.346 16.4a1.227 1.227 0 001.572 0l.33-.3v12.765a1.078 1.078 0 001.131 1.014h24.89a1.078 1.078 0 001.132-1.014V16.102l.331.3a1.226 1.226 0 001.6-.025.94.94 0 000-1.409zm-16.64 14.915V19.74h6.788v8.113zm14.708 0h-5.657v-9.127a1.078 1.078 0 00-1.132-1.014h-9.051a1.077 1.077 0 00-1.131 1.014v9.128H4.512v-13.78L15.826 3.932 27.14 14.074z"
            fill="currentColor"
        />
    </svg>
);

export const Stats: FunctionalComponent<Props> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            d="M28.827 1.897a3.166 3.166 0 00-2.28 5.369l-5.8 11.591a3.068 3.068 0 00-.384-.039 3.159 3.159 0 00-1.194.237l-4.496-5.058a3.135 3.135 0 00.406-1.522 3.173 3.173 0 10-5.567 2.058l-5.5 9.632a3.183 3.183 0 00-.567-.057 3.211 3.211 0 102.394 1.115l5.5-9.632a3.015 3.015 0 001.757-.18l4.5 5.061a3.135 3.135 0 00-.4 1.522 3.173 3.173 0 105.453-2.2l5.8-11.591a3.068 3.068 0 00.384.039 3.173 3.173 0 100-6.345"
            fill="currentColor"
        />
    </svg>
);

export const Profile: FunctionalComponent<Props> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            fill="currentColor"
            d="M15.956 15.503a7.753 7.753 0 007.752-7.752 7.752 7.752 0 10-13.229 5.481 7.5 7.5 0 005.481 2.271m-4.147-11.9A5.866 5.866 0 0121.826 7.75a5.866 5.866 0 11-10.013-4.147m17.706 21.143a19.165 19.165 0 00-.261-2.033 15.99 15.99 0 00-.5-2.045 10.11 10.11 0 00-.841-1.907 7.181 7.181 0 00-1.267-1.652 5.588 5.588 0 00-1.821-1.144 6.291 6.291 0 00-2.324-.421 2.359 2.359 0 00-1.26.534c-.378.246-.819.531-1.312.846a7.525 7.525 0 01-1.7.748 6.594 6.594 0 01-4.154 0 7.5 7.5 0 01-1.7-.748c-.488-.312-.93-.6-1.314-.847a2.359 2.359 0 00-1.259-.534 6.285 6.285 0 00-2.324.421 5.582 5.582 0 00-1.821 1.144 7.191 7.191 0 00-1.267 1.651 10.115 10.115 0 00-.84 1.907 15.963 15.963 0 00-.5 2.044 19.016 19.016 0 00-.261 2.034 27.35 27.35 0 00-.064 1.9 5.344 5.344 0 001.587 4.043 5.714 5.714 0 004.09 1.492h15.5a5.715 5.715 0 004.09-1.492 5.343 5.343 0 001.587-4.043c0-.648-.022-1.288-.065-1.9m-2.823 4.578a3.847 3.847 0 01-2.79.972h-15.5a3.846 3.846 0 01-2.789-.972 3.489 3.489 0 01-1-2.677c0-.6.02-1.2.06-1.77a17.154 17.154 0 01.236-1.832 14.152 14.152 0 01.44-1.8 8.228 8.228 0 01.684-1.55 5.328 5.328 0 01.932-1.221 3.7 3.7 0 011.211-.753 4.338 4.338 0 011.485-.286c.066.035.184.1.375.226.388.253.835.541 1.328.857a9.366 9.366 0 002.132.952 8.479 8.479 0 005.314 0 9.365 9.365 0 002.132-.953c.506-.323.94-.6 1.328-.856.191-.124.308-.191.374-.226a4.341 4.341 0 011.486.286 3.711 3.711 0 011.211.753 5.312 5.312 0 01.931 1.221 8.226 8.226 0 01.684 1.55 14.135 14.135 0 01.44 1.8 17.187 17.187 0 01.236 1.832c.04.569.06 1.164.06 1.769a3.49 3.49 0 01-1 2.677"
        />
    </svg>
);

export const Ranking: FunctionalComponent<Props> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            fill="currentColor"
            d="M30.73 19.993v-6.608a.96.96 0 00-.665-.914l-14.406-4.64a.96.96 0 00-.589 0L.665 12.475a.96.96 0 000 1.827l7.212 2.325v3.647c.029.934.911 1.819 2.493 2.5a12.966 12.966 0 004.994.961 12.967 12.967 0 004.994-.961c1.608-.689 2.494-1.593 2.494-2.544v-3.6l5.957-1.92v5.286a2.491 2.491 0 00-1.526 2.289v3.253a.96.96 0 00.96.96H31.3a.96.96 0 00.96-.96v-3.253a2.49 2.49 0 00-1.527-2.294M19.3 21.13a11.676 11.676 0 01-7.874 0 3.831 3.831 0 01-1.631-.98v-2.9l5.273 1.7a.961.961 0 00.589 0l5.273-1.7v2.9a3.839 3.839 0 01-1.631.979m-3.934-4.105L4.089 13.385l11.276-3.631 11.276 3.631zm14.972 7.556H29.2v-2.295a.567.567 0 111.134 0z"
        />
    </svg>
);

export const Badge: FunctionalComponent<{ fill?: string; stroke: string }> = ({
    fill = "#084460",
    stroke,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="140"
        viewBox="12 0 120 140"
    >
        <defs>
            <filter
                id="a"
                x={0}
                y={0}
                width="143"
                height="155"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={10} />
                <feGaussianBlur stdDeviation={10} />
            </filter>
        </defs>
        <path
            filter="url(#a)"
            d="M72.213 22.501c-23.8-.054-30.687 5.88-30.687 5.88s-9.546 38.519-8.995 51.6c.292 6.938 7.231 11.487 7.231 11.487 23.036 15.683 31.881 21.03 31.881 21.03s17.245-10.759 31.876-21.03c0 0 6.671-4.55 6.966-11.487.559-13.149-8.995-51.868-8.995-51.868s-5.481-5.663-29.277-5.612z"
            fill="#888"
        />
        <path
            d="M72.213 22.501c-23.8-.054-30.687 5.88-30.687 5.88s-9.546 38.519-8.995 51.6c.292 6.938 7.231 11.487 7.231 11.487 23.036 15.683 31.881 21.03 31.881 21.03s17.245-10.759 31.876-21.03c0 0 6.671-4.55 6.966-11.487.559-13.149-8.995-51.868-8.995-51.868s-5.481-5.663-29.277-5.612z"
            fill={fill}
            stroke={stroke}
            {...{ "stroke-width": 5 }}
        />
    </svg>
);

export const Check: FunctionalComponent<{ className?: string }> = ({
    className,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.15"
        height="9.851"
        className={className}
    >
        <path
            d="M3.496 9.851l-3.5-4.495 2.033-1.581 1.655 2.128 5.592-5.9 1.87 1.772z"
            fill="#fff"
        />
    </svg>
);

export const LevelIcon: FunctionalComponent = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.959" height="15.488">
        <g fill="none">
            <path d="M5.906 13.231l-2.594-2.844a5.252 5.252 0 010-6.945 4.206 4.206 0 016.335 0 5.252 5.252 0 010 6.945l-2.594 2.844a.762.762 0 01-1.147 0z" />
            <path
                d="M6.48 13.488a.775.775 0 00.573-.26l2.594-2.844c1.749-1.918 1.749-5.027 0-6.945C8.772 2.479 7.626 2 6.479 2c-1.146 0-2.292.48-3.167 1.439-1.75 1.918-1.75 5.027 0 6.945l2.594 2.844c.158.173.366.26.573.26m0 2a2.786 2.786 0 01-2.05-.912l-2.595-2.844C.651 10.435 0 8.722 0 6.912 0 5.1.65 3.387 1.834 2.09 3.064.743 4.714 0 6.48 0a6.17 6.17 0 013.962 1.438h.088l.595.652c1.183 1.297 1.835 3.009 1.835 4.82 0 1.812-.652 3.524-1.835 4.82l-2.593 2.845c-.53.58-1.278.912-2.052.912z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const LoginIcon: FunctionalComponent = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.189" height="20.189">
        <defs>
            <style dangerouslySetInnerHTML={{ __html: ".a{fill:#084460}" }} />
        </defs>
        <path
            className="a"
            d="M18.507 0H8.412A1.684 1.684 0 006.73 1.682v2.945a.421.421 0 10.841 0V1.682a.842.842 0 01.841-.841h10.095a.842.842 0 01.841.841v16.825a.842.842 0 01-.841.841H8.412a.842.842 0 01-.841-.841v-2.945a.421.421 0 10-.841 0v2.944a1.684 1.684 0 001.682 1.682h10.095a1.684 1.684 0 001.682-1.682V1.682A1.684 1.684 0 0018.507 0z"
        />
        <path
            className="a"
            d="M9.39 14.411a.421.421 0 00.568.62l5.047-4.627a.421.421 0 000-.62L9.958 5.157a.421.421 0 10-.568.62l4.25 3.9H.421a.421.421 0 100 .841H13.64z"
        />
    </svg>
);

export const ChevronRight: FunctionalComponent<{ className?: string }> = ({
    className,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5.539"
        height="10.951"
        className={className}
    >
        <path
            d="M.428 10.951a.428.428 0 01-.3-.73l4.434-4.443a.428.428 0 000-.605L.128.73a.428.428 0 01.606-.6l4.434 4.443a1.285 1.285 0 010 1.815L.735 10.831a.426.426 0 01-.307.12z"
            fill="currentColor"
        />
    </svg>
);

export const Close: FunctionalComponent<{ className?: string }> = ({
    className,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className={className}
    >
        <path
            {...{
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": 3,
            }}
            d="M6 18L18 6M6 6l12 12"
        />
    </svg>
);

export const Spinner: FunctionalComponent<{ className?: string }> = ({
    className = "",
}) => (
    <svg
        className={`animate-spin ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
    >
        <circle
            className="opacity-25"
            cx={12}
            cy={12}
            r={10}
            stroke="currentColor"
            strokeWidth={4}
        />
        <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
    </svg>
);

export const Pencil: FunctionalComponent<{ className?: string }> = ({
    className = "",
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 20 20"
        fill="currentColor"
    >
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
    </svg>
);

export const QuestionMark: FunctionalComponent<{ className?: string }> = ({
    className = "",
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        className={className}
    >
        <path
            fill="currentColor"
            d="M16 32a16 16 0 1116-16 16.019 16.019 0 01-16 16zm0-29.538A13.538 13.538 0 1029.538 16 13.553 13.553 0 0016 2.462zm0 0"
        />
        <path
            fill="currentColor"
            d="M16 21.967a1.212 1.212 0 01-1.211-1.211v-.637a7.663 7.663 0 013.356-6.117 3.633 3.633 0 10-5.778-2.932 1.211 1.211 0 01-2.422 0 6.054 6.054 0 119.631 4.887 5.312 5.312 0 00-2.366 4.163v.637a1.212 1.212 0 01-1.211 1.211zm0 0M16 27.07a1.2 1.2 0 01-.848-2.059 1.214 1.214 0 011.7 0 1.211 1.211 0 01-.848 2.059zm0 0"
        />
    </svg>
);

export const Lock: FunctionalComponent<{ className?: string }> = ({
    className = "",
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        className={className}
    >
        <path
            fill="currentColor"
            d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-5 8.239c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2zm3-9.413c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587z"
        />
    </svg>
);
